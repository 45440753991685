import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/auc/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const AUC = ({ data, path }) => {

    return (
        <Project
            title="The Atlanta University Center"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                The Atlanta University Center (AUC) Art History and Curatorial 
                Studies Collective is an innovative art program designed to bring 
                together students from Spelman College, Morehouse College, and Clark 
                Atlanta University. This collective aims to provide a comprehensive and 
                collaborative educational experience in the fields of art history and curatorial studies.
                </p>
                <p>
                Spelman College and its partners needed a modern, efficient, and visually 
                appealing website to replace their existing, outdated platform. 
                The previous site was clunky, difficult to navigate, and lacked 
                the dynamism required to engage users effectively. Additionally, 
                the application process for their preparatory program for high school 
                students was slow and cumbersome, hindering potential applicants.
                </p>
                <p>
                The team at nebo designed detailed wireframes, created a fresh, 
                modern design, and then I, along with the engineering team, handled the website development.
                </p>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('auc')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('auc')} />
                </div>
                <FeatureCreator projectData={projects.get('auc')} />
             </Content>
        </Project>
    )
}


AUC.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default AUC
